import { defineStore } from 'pinia';
import filesystemApiService from "@/services/filesystem-api-service.js";
import { ToastProgrammatic as Toast } from 'buefy';

export const useDownloadStore = defineStore('download', {
    state: () => ({
      firstZipMessage: "Starting your download",
      secondZipMessage: "Adding all files to your new download",
      thirdZipMessage: "Looking good (with lots of content), still working on your download, almost ready",
      inProgressZipMessage: "Download is taking longer than expected, we will send it to your email",
    }),
    getters: {

    },
    actions: {
        startDownloadMessage(zipItemsLength){
            return Toast.open({
                duration: 8000000,
                message: `&#11015;&nbsp;&nbsp; ${this.firstZipMessage} (${zipItemsLength}) now...`,
                position: 'is-bottom',
                type: 'is-black'
            });
        },
        midDownloadMessage(){
            return Toast.open({
                duration: 8000000,
                message: `&#11015;&nbsp;&nbsp;${this.secondZipMessage}...`,
                position: 'is-bottom',
                type: 'is-black'
            });
        },
        lastDownloadMessage(){
            return Toast.open({
                duration: 8000000,
                message: `&#11015;&nbsp;&nbsp;${this.thirdZipMessage}...`,
                position: 'is-bottom',
                type: 'is-black'
            });
        },
        inProgressMessage(){
            return Toast.open({
                duration: 8000000,
                message: `&#11015;&nbsp;&nbsp;${this.inProgressZipMessage}...`,
                position: 'is-bottom',
                type: 'is-black'
            });
        },
        async filesystemZipDownload(numItems, payload) {
            let zipToast = this.startDownloadMessage(numItems);
            let isShowToast = true;
            setTimeout(() => {
                zipToast?.close();
                if(isShowToast) {
                  zipToast = this.midDownloadMessage();
                }
            }, 2000);
            setTimeout(() => {
                zipToast?.close();
                if(isShowToast) {
                  zipToast = this.lastDownloadMessage();
                }
            }, 4000);
            const respData = await filesystemApiService.getAssetDownloadZip(payload).catch(() => {
                zipToast?.close();
                isShowToast = false;
                const suggest = "Please refresh and try again later or contact support@harbourshare.com";
                const issue = "Unable to create download at this time.";
                alert(`${issue} ${suggest}`);
            });
            if(respData.isinprogress) {
                zipToast?.close();
                if(isShowToast) {
                  zipToast = this.inProgressMessage();
                }
                isShowToast = false;
                setTimeout(() => {
                    zipToast.close();
                }, 8000);
            } else {
                zipToast?.close();
                isShowToast = false;
                const downloadUrl = respData.zipdownloadsignedurl;
                window.open(downloadUrl);
            }
        },
        async fileSystemDownloadOriginal(payload){
            const respData = await filesystemApiService.fileSystemDownloadOriginal(payload).catch(() => {
                const suggest = "Please refresh and try again later or contact support@harbourshare.com";
                const issue = "Unable to create download at this time.";
                alert(`${issue} ${suggest}`);
            });
            if (respData.alloweddownload == true){
                let assetDataUrl = respData.signeddataurl;
                window.open(assetDataUrl);
            }
            else {
                alert("No permission to download file. Please contact your organization admin for access");
            }
        },
        async fileSystemDownloadAssetOnly(payload){
            const toast = Toast.open({
                duration: 8000000,
                message: `Starting your download now...`,
                position: 'is-bottom',
                type: 'is-black',
            });
            try {
                const respData = await filesystemApiService.getSignedAssetDataUrl(payload).catch(() => {
                    const suggest = "Please refresh and try again later or contact support@harbourshare.com";
                    const issue = "Unable to create download at this time.";
                    alert(`${issue} ${suggest}`);
                });
                let assetDataUrl = respData.signeddataurl;
                window.open(assetDataUrl);
            } catch (err) {
                const suggest = "Please refresh and try again later or contact support@harbourshare.com";
                const issue = "Unable to create download at this time.";
                Toast.open({
                    duration: 2500,
                    message: `${issue} ${suggest}`,
                    position: 'is-top',
                    type: 'is-warning',
                });
                console.error(err);
            } finally {
                toast.close();
            }
        },
    },
});
